import React, { useEffect, useState } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa'; // Importing arrow icons
import 'bootstrap/dist/css/bootstrap.min.css';

const ScrollButtons = () => {
  const [isAtTop, setIsAtTop] = useState(true);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
      setShowButton(window.scrollY > 100); // Show button after scrolling down 100px
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scroll = (direction) => {
    window.scrollTo({
      top: direction === 'top' ? 0 : document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {showButton && (
        <div className="position-fixed bottom-0 end-0 p-3">
          <button
            onClick={() => scroll(isAtTop ? 'bottom' : 'top')}
            aria-label={isAtTop ? 'Scroll to bottom' : 'Scroll to top'}
            className="btn d-flex align-items-center justify-content-center"
            style={{
              width: '40px', // Circular button width
              height: '40px', // Circular button height
              border: 'solid skyblue',
              borderRadius: '50%', // Make it circular
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              transition: 'opacity 0.1s',
              opacity: isAtTop ? 0.5 : 1, // Change opacity based on scroll position
            }}
          >
            {isAtTop ? (
              <FaArrowDown className="text-primary" style={{ fontSize: '24px' }} />
            ) : (
              <FaArrowUp className="text-primary" style={{ fontSize: '24px' }} />
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default ScrollButtons;
