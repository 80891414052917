import React from 'react';
import cat1 from '../assets/img/web-design-illustration.png.png';
import cat2 from '../assets/img/graphic-design-illustration.png.jpg';
import cat3 from '../assets/img/frontend-dev-illustration.png.jpg';
import cat4 from '../assets/img/backend-dev-illustration.png.jpg';

const cardStyle = {
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxShadow: '5px 4px 1px rgba(0, 0, 0, 0.5)', /* Hard shadow effect */

};

const imgStyle = {
  objectFit: 'cover',
  flex: 1,
  width: '100%',
};

const textOverlayStyle = {
  width: '100%',
  boxShadow: '0 -2px 8px rgba(0,0,0,0.2)',
  color: '#333',
  position: 'absolute',
  bottom: 0,
  start: 0,
  padding: '3px 4px',
  textAlign: 'center',
  backgroundColor: 'white',
};

const categories = [
  { img: cat1, alt: 'Web Design Category', text: 'Web Design' },
  { img: cat2, alt: 'Graphic Design Category', text: 'Graphic Design' },
  { img: cat3, alt: 'Frontend Development Category', text: 'Frontend Development' },
  { img: cat4, alt: 'Backend Development Category', text: 'Backend Development' }
];

const Categories = () => (
  <section id='categories' className="container py-5">
    <header className="mb-5 border-bottom text-center">
      <h1><span>&#8621;</span>Courses Categories<span>&#8621;</span></h1>
    </header>
    <div className="row gx-3">
      {categories.map((cat, index) => (
        <article key={index} className="col-lg-3 col-md-6 mb-3">
          <a className="position-relative d-block overflow-hidden rounded border border-primary" style={cardStyle}>
            <img className="img-xxl" src={cat.img} alt={cat.alt} style={imgStyle} />
            <footer className="position-absolute" style={textOverlayStyle}>
              <h5 className="m-0">{cat.text}</h5>
            </footer>
          </a>
        </article>
      ))}
    </div>
  </section>
);

export default Categories;
