// App.js
import React, { useState } from 'react';
import Footer from './components/Footer';
import Testimonial from './components/Testimonial';
import Team from './components/Team';
import Service from './components/Service';
import Navbar from './components/Navbar';
import Courses from './components/Courses';
import Categories from './components/Categories';
import Contact from './components/Contact';
import IconCloudDemo from './components/IconCloudDemo';
import ScrollButtons from './components/ScrollButtons';
import SocialMediaIcons from './components/SocialMediaIcons';
// import Register from './components/Register';
import './style/styles.css';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`App ${darkMode ? 'bg-dark text-light' : 'bg-light text-dark'}`}>
      <div className="navbar-container">
        <Navbar darkMode={darkMode} toggleTheme={toggleTheme} />
      </div>
      <div className="app-content dotted-background">
        <IconCloudDemo darkMode={darkMode} />
        <Service darkMode={darkMode} />
        <Categories darkMode={darkMode} />
        <Courses darkMode={darkMode} />
        <Team darkMode={darkMode} />
        <Testimonial darkMode={darkMode} />
        <Contact darkMode={darkMode} />
        <Footer darkMode={darkMode} />
        {/* <Register darkMode={darkMode}/> */}
      </div>
      <div>
        < SocialMediaIcons darkMode={darkMode} />
      </div>
      <div >
        <ScrollButtons />
      </div>
    </div>
  );
}

export default App;
