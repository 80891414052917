import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt, faEnvelopeOpen, faClock } from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <section id="contact" className="container  d-flex justify-content-center align-items-center" style={{ minHeight: '0vh', }}>
      <div className="container">
        <header className="mb-5 border-bottom   text-center wow fadeInUp" data-wow-delay="0.1s">
          <h1 className="" >
            <span>&#8621;</span>Contact For Any Query<span>&#8621;</span>
          </h1>
        </header>
        <div className="row g-4">
          <article className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="border border-primary rounded p-4 h-100">
              <h5>Get In Touch</h5>
              <div className="d-flex align-items-center mb-3">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Office</h5>
                  <p className="mb-0">Bulandshahr, Uttar Pradesh, Pin:- 203203</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <FontAwesomeIcon icon={faPhoneAlt} className="text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Mobile</h5>
                  <p className="mb-0">+91 9027587069</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Email</h5>
                  <p className="mb-0">internsteps4u@gmail.com</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <FontAwesomeIcon icon={faClock} className="text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Office Hours</h5>
                  <p className="mb-0">Mon - Fri: 9:00 AM to 6:00 PM</p>
                </div>
              </div>
            </div>
          </article>
          <article className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="border border-primary rounded p-4 h-100">
              <div className="embed-responsive">
                <iframe
                  className="position-relative rounded w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3511.294076723119!2d77.78405207534767!3d28.34995747582017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ca5e0d60f69a3%3A0x8fffc2fe9c860ab6!2sSRS%20(education%20of%20computer)!5e0!3m2!1sen!2sin!4v1721733516231!5m2!1sen!2sin"
                  frameBorder="0"
                  style={{ minHeight: "300px", border: "0" }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex="0"
                  title="Google Maps Location"
                ></iframe>
              </div>
            </div>
          </article>
          <article className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="border border-primary rounded p-4 h-100">
              <form action="https://docs.google.com/forms/d/e/1FAIpQLSf4LrWOVl_N7JAqk9OX1sJ0knZpgA6zVW7nKqww-cYHZ7twLQ/formResponse" method="POST" target="_blank">
                <fieldset>
                  <div className="row g-3">
                    {/* <!-- Personal Information --> */}
                    <div className="col-md-6">
                      <div className="">
                        <input type="text" className="form-control" id="name" name="entry.2005620554" placeholder="Name" required />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <input type="email" className="form-control" id="email" name="entry.1045781291" placeholder="Email" required />
                      </div>
                    </div>

                    {/* <!-- Contact Details --> */}
                    <div className="col-md-6">
                      <div className="">
                        <input
                          type="text"
                          className="form-control"
                          id="phonenumber"
                          name="entry.1166974658"
                          placeholder="Mobile"
                          required
                          pattern="[0-9]{10}"
                          title="Please enter a 10-digit phone number (e.g., 1234567890)"
                          maxLength="10"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="">
                        <input type="text" className="form-control" id="address" name="entry.1065046570" placeholder="Address" required />
                      </div>
                    </div>

                    {/* <!-- Subject and Message --> */}
                    <div className="col-12">
                      <div className="">
                        <input type="text" className="form-control" id="subject" name="entry.848348626" placeholder="Subject" required />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="">
                        <textarea className="form-control" id="message" name="entry.839337160" placeholder="Leave a message here" required></textarea>
                      </div>
                    </div>

                    {/* <!-- Submit Button --> */}
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Contact;
