import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faClock, faUser } from '@fortawesome/free-solid-svg-icons';

const courseData = [
  { id: 1, title: 'Frontend Development Course for Beginners', price: '₹8000', instructor: 'Ram Sir', duration: '8 Months', students: '5 Students', color: '#f8d7da' },
  { id: 2, title: 'Backend Development Course for Beginners', price: '₹8000', instructor: 'Ravi Sir', duration: '8 Months', students: '4 Students', color: '#f8d7da' },
  { id: 3, title: 'Fullstack Development Course for Beginners', price: '₹12000', instructor: 'Ravi Sir', duration: '8 Months', students: '3 Students', color: '#fff3cd' },
  { id: 4, title: 'MEAN Full Stack Development Course', price: '₹12000', instructor: 'Ram Sir', duration: '8 Months', students: '4 Students', color: '#fff3cd' },
  { id: 5, title: 'MERN Full Stack Development Course', price: '₹12000', instructor: 'Ram Sir', duration: '8 Months', students: '5 Students', color: '#d4edda' },
  { id: 6, title: 'DSA Course for Beginners', price: '₹12000', instructor: 'Nishu Sir', duration: '12 Months', students: '6 Students', color: '#d4edda' },
  { id: 7, title: 'Java Programming Course', price: '₹12000', instructor: 'Divya Mam', duration: '8 Months', students: '3 Students', color: '#cce5ff' },
  { id: 8, title: 'Graphic Designing Course', price: '₹12000', instructor: 'Ram Sir', duration: '8 Months', students: '3 Students', color: '#cce5ff' }
];

const Courses = () => {
   

  return (
    <section id='courses' className="container mb-5">
      <header className="mb-5 border-bottom text-center wow fadeInUp" data-wow-delay="0.1s">
        <h1>
          <span>&#8621;</span>Explore Our Courses<span>&#8621;</span>
        </h1>
      </header>
      <div className="row gy-4">
        {courseData.map(course => (
          <article className="col-md-6 col-lg-4 col-xl-3" key={course.id}>
            <div
              className={`card d-flex flex-column h-100`}
              style={{
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                borderRadius: '10px',
                cursor: 'pointer',
                backgroundColor: course.color,
                boxShadow: '5px 4px 1px rgba(0, 0, 0, 0.5)', /* Hard shadow effect */

              }}
            >
              <div className="card-body d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                <h2 className="card-title mb-3" style={{ fontSize: '1.1rem', fontWeight: '600' }}>{course.title}</h2>
                <p className="mb-2" style={{ fontSize: '1rem', fontWeight: '500' }}><strong>Price:</strong> {course.price}</p>
                <div className="d-flex justify-content-between text-muted mb-3">
                  <small><FontAwesomeIcon icon={faUserTie} className="me-2" />{course.instructor}</small>
                  <small><FontAwesomeIcon icon={faClock} className="me-2" />{course.duration}</small>
                  <small><FontAwesomeIcon icon={faUser} className="me-2" />{course.students}</small>
                </div>
                <footer className="text-center mt-auto">
                  <a
                   
                    className="btn btn-outline-primary"
                    style={{
                      borderRadius: '5px', /* Square shape */
                      fontWeight: '500',
                      boxShadow: '5px 4px 1px rgba(0, 0, 0, 0.5)', /* Hard shadow effect */
                    }}
                  >
                    Apply Now
                  </a>
                </footer>
              </div>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
}

export default Courses;
