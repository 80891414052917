import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChalkboardTeacher, faLaptop, faProjectDiagram,
    faUsers, faCertificate, faClock, faBullhorn,
    faSuitcase, faExternalLink
} from "@fortawesome/free-solid-svg-icons";

const services = [
    { icon: faChalkboardTeacher, title: "Skilled Instructors", description: "Learn from industry experts with years of experience and in-depth knowledge in their respective fields.", color: '#cce5ff' }, // Light Blue
    { icon: faLaptop, title: "Online Classes", description: "Attend live classes and interactive sessions from the comfort of your home, anywhere in the world.", color: '#cce5ff' }, // Light Blue
    { icon: faProjectDiagram, title: "Hands-On Projects", description: "Gain practical experience by working on real-world projects that reinforce your learning and skills.", color: '#cce5ff' }, // Light Blue
    { icon: faUsers, title: "Collaborative Environment", description: "Work with peers and mentors in a collaborative online learning environment.", color: '#cce5ff' }, // Light Blue
    { icon: faCertificate, title: "Certifications", description: "Earn certifications to showcase your skills and knowledge.", color: '#cce5ff' }, // Light Blue
    { icon: faClock, title: "Self-Paced Learning", description: "Take control of your learning schedule with self-paced courses and resources.", rightIcon: faExternalLink, rightIconLink: "/tutorial-box", color: '#cce5ff' }, // Light Blue
    { icon: faBullhorn, title: "Interview Preparation", description: "Prepare for interviews in any technology with expert guidance and mock interviews.", rightIcon: faExternalLink, rightIconLink: "/interview-preparation", color: '#cce5ff' }, // Light Blue
    { icon: faSuitcase, title: "Internship Opportunities", description: "Explore various internship opportunities to gain practical experience in your chosen field.", rightIcon: faExternalLink, rightIconLink: "https://your-internship-link.com", color: '#cce5ff' } // Light Blue
];

const Service = () => (
    <section id="service" className="container   " >
        <header className="mb-5 border-bottom   text-center wow fadeInUp" data-wow-delay="0.1s">
            <h1><span>&#8621;</span> Service <span>&#8621;</span></h1>
            <h2><span>&#8621;</span> Learning | Internship | Training <span>&#8621;</span></h2>
        </header>
        <div className="row gx-4 gy-4">
            {services.map((service, index) => (
                <ServiceCard key={index} {...service} />
            ))}
        </div>
    </section>
);

const ServiceCard = ({ icon, title, description, color }) => (
    <article className="col-lg-3 col-sm-6 d-flex align-items-stretch">
        <div className="service-item text-center pt-3 h-100" style={{ ...styles.card, backgroundColor: color }}>
            <FontAwesomeIcon icon={icon} className="fa-3x mb-4 text-dark" />
            <h5 className="mb-3 text-dark">{title}</h5>
            <p className="text-muted">{description}</p>
        </div>
    </article>
);

const styles = {
    card: {
        transition: 'transform 1s ease-in-out, box-shadow 0.2s ease-in-out',
        borderRadius: '10px',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        boxShadow: '5px 4px 1px rgba(0, 0, 0, 0.5)', /* Hard shadow effect */

    },
};

export default Service;
